<script setup lang="ts">
import { currentLocale, getLocales, setLocale } from "@/plugins/i18n";
import { onMounted, ref, watch, inject, computed } from "vue";
import { QSelect } from "quasar";
import { VueCookies } from "vue-cookies";

// CONST
const initialLocales = ref([]);
const locales = ref([])
const selectedLocale = ref<string>(currentLocale().toLocaleUpperCase());
const cookies = inject<VueCookies>('$cookies')
const currentLocal = computed(() => currentLocale())
const props = defineProps<{
    languageEnabled: string[]
}>();

// LIFECYCLE
onMounted(() => {
    initialLocales.value = getLocales();
    initialLocales.value.forEach((locale) => {
        let value = locale.toLocaleUpperCase();
        locales.value.push(value);
    })
});

// WATCH
watch(selectedLocale, (newValue) => {
    let value = newValue.toLocaleLowerCase();
    cookies.set('locale', value)
    setLocale(value);
})
watch(currentLocal, (newValue) => {
    selectedLocale.value = newValue.toLocaleUpperCase()
})

</script>

<template>
    <div class="locale-select">
        <q-select label-color="primary" color="primary" borderless v-model="selectedLocale" :options="locales">
            <template v-slot:prepend>
                <q-icon name="o_language"/>
            </template>
        </q-select>
    </div>
</template>

<style lang="scss">
.locale-select {

    .q-select {
        text-transform: uppercase;
    }

    .q-field--auto-height .q-field__control {
        min-height: 40px !important;
        height: 40px !important;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }

    .q-field__prepend {
        padding-right: 6px;
    }

    .q-field__append {
        padding-left: 6px;
    }
}
</style>
