<script setup lang="ts">

import supportingInfoContainer from '@/components/supportingInfoContainer.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import { DTOContext } from "@/store/context.interface";
import { QList, QExpansionItem } from 'quasar';

const props = defineProps<{
    infos: DTOContext[];
}>();

</script>
<template>
    <div class="project-attachments">
        <BaseCard>
            <template v-slot:content>
                <q-list class="rounded-borders">
                    <!--                    <q-expansion-item>
                        <template v-slot:header>
                            <q-item-section avatar>
                                <q-icon name="link"></q-icon>
                            </q-item-section>

                            <q-item-section>
                                <div class="attachments">
                                    <div>
                                        {{ $t("consultation.context.attachment", { count: length }) }}
                                    </div>
                                    <div class="attachments_count">
                                        [ {{ length }} ]
                                    </div>
                                </div>
                            </q-item-section>
                        </template>
<template v-slot>-->
                    <q-card>
                        <q-card-section>
                            <supportingInfoContainer :supporting-infos="props.infos" />
                        </q-card-section>
                    </q-card><!--
                        </template>
</q-expansion-item>-->
                </q-list>
            </template>
        </BaseCard>
    </div>
</template>
<style lang="scss">
.project-attachments {
    display: flex;

    .base-card__wrapper {
        width: 100%;

        .attachments {
            display: flex;
            flex-direction: row;
            gap: $space-md;
        }
    }
}
</style>