import { Context } from '@/store/context.interface';
import { Form } from '@/store/form.Interface';
import { LanguageTranslation } from "@/store/language.interface";
import { StoredFile } from './common.interface';

export interface DTOSection {
    id: number;
    created_at: string;
    updated_at: string;
    status: number;
    consultation_id: number;
    order_num: number;
    begin_date: string | null;
    end_date: string | null;
    title: LanguageTranslation;
    description: LanguageTranslation;
    summary: SectionSummary;
    citizen_proposal_enabled: boolean;
    banner_file: StoredFile;
    contextOpened: number | null;
}

export interface Section {
    id: number;
    createdAt: string;
    updatedAt: string;
    status: number;
    consultationId: number;
    orderNum: number;
    beginDate: string | null;
    endDate: string | null;
    title: LanguageTranslation;
    description: LanguageTranslation;
    context: Context[],
    summary: SectionSummary,
    form: Form[],
    citizenProposalEnabled: boolean,
    bannerFile: StoredFile,
    contextOpened: number | null
}

interface SectionSummary {
    polls?: number;
    votes?: number;
    surveys?: number;
    user_participation?: number;
}


export function parseSection(dtoSection: DTOSection): Section {
    return {

        id: dtoSection.id,
        createdAt: dtoSection.created_at,
        updatedAt: dtoSection.updated_at,
        status: dtoSection.status,
        consultationId: dtoSection.consultation_id,
        orderNum: dtoSection.order_num,
        endDate: dtoSection.end_date,
        beginDate: dtoSection.begin_date,
        title: dtoSection.title,
        description: dtoSection.description,
        summary: dtoSection.summary,
        context: [],
        form: [],
        citizenProposalEnabled: dtoSection.citizen_proposal_enabled,
        bannerFile: dtoSection.banner_file,
        contextOpened: dtoSection.contextOpened
    };
}
