<script setup lang="ts">

import { QLinearProgress } from "quasar";
import { getTranslation } from "@/utils/data-display-utils";

// PROPS
const props = defineProps<{
    choices: any;
    setActiveScreen?: string;
    statistiques: any;
    response?: any[];
    otherChoice: boolean;
    otherChoiceLabel?: string;
}>();

</script>
<template>
    <div class="image-results" >
        <div v-for="choice in props.choices">
            <q-linear-progress size="100px" :value='props.statistiques[choice.id].percentage/100'>
                <div class="image-results__content">
                    <div class="image-results__content-image">
                        <img :src="choice.image.url"/>
                        {{getTranslation(choice, 'text')}}
                    </div>
                    <div class="image-results__content-stat">
                        <div class="label" v-if="props.statistiques[choice.id]">
                            {{ props.statistiques[choice.id].percentage }}%
                        </div>
                    </div>
                </div>
                
            </q-linear-progress>
        </div>
    </div>
</template>
<style lang="scss">
.image-results {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .q-linear-progress {
        background-color: $color-neutral-grey-200 !important;
        color: $color-primary-12;
        border-radius: 8px !important;
        height: 100%;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin: $space-sm;
        height: 100%;
        gap: $space-md;

        &-image {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $space-lg;
            position: relative;
            z-index: 2;
            font-size: $body-text;
            color: $color-primary-darken;

            img {
                height: 80px;
                border-radius: 8px;
            }
        }

        &-stat {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;

            .label {
                font-size: $body-text;
                color: $color-primary-darken;
            }
        }
    }
}
</style>