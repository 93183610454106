<script setup lang="ts">

import { getTranslation } from "@/utils/data-display-utils";

// PROPS
const props = defineProps<{
    choice: any;
}>();

</script>

<template>
    <q-btn flat no-caps align="around" class="base-image-button">
        <div class="image">
            <img :src="props.choice.image?.url"/>
        </div>
        <div class="text">
            {{ getTranslation(choice, 'text') }}
        </div>
    </q-btn>
</template>

<style lang="scss">
.q-btn.base-image-button {
    justify-content: space-between;
    padding: $space-xs;
    border: 2px solid $color-neutral-grey-300;
    border-radius: 8px;
    background-color: $color-neutral-white;
    color: $color-primary-darken;

    &:hover{
        background-color: $color-neutral-grey-100;
        font-weight: bold;
    }

    &:active{
        border: 1px solid $color-primary;
    }

    div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        border-radius: 8px;
        max-height: 200px;
        max-width: 200px;
    }

    .primary-outline {
       
    }

}

@media screen and (max-width: $breakpoint-sm) {
    .q-btn.base-image-button {

        img {
            border-radius: 8px;
            max-height: 90%;
            max-width: 90%;
        }

        .image {
            width: 60%;
            display: flex;
            align-content: center;
            justify-content: center;
        }

        .text {
            display: flex;
            justify-content: center;
            padding: 0 $space-xs;
            width: 40%;
        }

    }
}
</style>