<script setup lang="ts">
import { useI18n } from "vue-i18n";


// PROPS
const props = defineProps<{
    type: string;
    keyInformation: object;
    isSmall?: boolean;
    isTiny?: boolean;
    isTitle?:boolean
}>();

// CONST
const {t} = useI18n();


// FUNCTIONS
function displayedInformation(key: string, value: string) {
    if (props.keyInformation) {
        return `${t(`${props.type}.${key}`, value)}`
    } else {
        return 'No Information'
    }
}
</script>

<template>
	<div v-if="keyInformation" class="key-infos">
		<div
			v-for="[key, value] in Object.entries(keyInformation)"
			:key="key"
			class="key-infos__info"
			:class="{ 'is-small': isSmall, 'is-tiny': isTiny, 'is-title': isTitle }"
		>
			<div class="key-infos__info--value">{{ value }}</div>
			<div class="key-infos__info--key">
                {{ displayedInformation(key, value) }}
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.key-infos {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;

	&__info {
		font-size: $body-text;
		margin-right: $space-md;
		display: flex;

        &.is-small {
            font-size: $small-text;
            margin-right: $space-sm;
        }

        &.is-tiny {
            font-size: 10px;
            margin-right: $space-sm;
        }

        &--value {
            font-weight: bold;
            margin-right: $space-xs;
        }
    }
}

@media screen and (max-width: $breakpoint-sm) {
  .key-infos {
    &__info {
      &.is-title {
        display: flex;
        justify-content: center;
        width: 50%;
      }
      margin-right: 0;
    }

  }
}
</style>
