import { defineStore } from 'pinia';
import { Consultation, DTOConsultation, parseConsultation } from '@/store/consultation.interface';
import { DTOSection, parseSection, Section } from "@/store/section.interface";
import { DTOContext, parseContext } from "@/store/context.interface";
import { CustomPage, DTOCustomPage, parseCustomPage } from "@/store/customPage.interface";
import {
    DTOForm,
    parseForm,
    PostFormCommentActionResponse,
    PostFormCommentResponse,
    PostFormVoteResponse
} from '@/store/form.Interface';
import { diffInDays, todayInTimestamp } from "@/utils/date-util";
import { constant } from "@/constant";
import { LanguageTranslation } from "@/store/language.interface";
import { useUserStore } from "@/store/userStore";
import { DTOProject, parseProject, Project } from './project.interface';
import { HomepageItem, StoredFile } from './common.interface';

export interface DTOCommunity {
    id: number,
    slug: string,
    created_at: string,
    status: number,
    contact_email: string | null,
    website: string | null,
    phone: string | null,
    google_analytics_code: string | null,
    clarity_code: string | null,
    title: LanguageTranslation,
    description: LanguageTranslation,
    features: CommunityFeatures,
    banner_file: StoredFile,
    logo_file: StoredFile,
    favicon_file: StoredFile,
    language_enabled: string[],
    contact_info: ContactInfo,
    options: CommunityOptions,
    organization_name: string | null
}

export interface Community {
    id: number,
    slug: string,
    createdAt: string,
    status: number,
    contactEmail: string | null,
    website: string | null,
    phone: string | null
    googleAnalyticsCode: string | null,
    clarityCode: string | null,
    title: LanguageTranslation,
    description: LanguageTranslation,
    features: CommunityFeatures,
    bannerFile: StoredFile,
    logoFile: StoredFile,
    faviconFile: StoredFile,
    consultations: Consultation[],
    customPages: CustomPage[],
    projects: Project[],
    languageEnabled: string[],
    contactInfo: ContactInfo,
    options: CommunityOptions,
    organizationName: string
}

export interface CommunityOptions {
    gender: boolean;
    custom_homepage: boolean;
    analytic: {
        clarity: boolean;
        ga: boolean;
    },
    export: {
        excel: boolean;
        pdf: boolean;
    },
    consultation: {
        allowed_private: boolean;
        citizen_proposal: boolean;
        i_believe_i_care: boolean;
        map: boolean;
        moderation_comment: boolean;
    }
    loi96: boolean,
    social_networks: {
        facebook: {
            enabled: boolean,
            url: string
        }
        instagram: {
            enabled: boolean,
            url: string
        }
        linkedin: {
            enabled: boolean,
            url: string
        }
        x: {
            enabled: boolean,
            url: string
        }
    }
}

interface ContactInfo {
    email: string,
    phone: string
}

interface CommunityFeatures {
    sociodemographicAllowed: number;
    mapsAllowed: number;
    exportAllowed: number;
    privateThemeAllowed: number;
    publicProposalAllowed: number;
    sociodemographic_white_background: number;
    moderateEmail: number;
    voila_open_id: number;
    use_gender: number;
    anon_votes: number;
    languages: string;
    notifSuperAdmin: number | null;
    IFrameEnabled: number;
    portal: number | null;
    portal_login: number;
    portal_community_url: string | null;
    portal_community_name: string | null;
    GaEnabled: number;
    ClarityEnabled: number;
    moderateEmailAdmin: number;
}

export const useCommunityStore = defineStore('communityStore', {
    state: () => ({
        community: {} as Community,
        isLoaded: false,
        sectionLoaded: false,
        formsLoaded: false,
        customPageLoaded: false
    }),
    actions: {
        setCommunity(dtoCommunity: DTOCommunity) {
            this.community = parseCommunity(dtoCommunity);
        },
        setConsultation(consultationsDTO: DTOConsultation[]) {
            this.community.consultations = consultationsDTO.map(parseConsultation);
            this.isLoaded = true;
        },
        setSections(sectionDTO: DTOSection[], consultationId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            consultation.section = sectionDTO.map(parseSection)
            this.sectionLoaded = true;
        },
        setConsultationContext(contextDTO: DTOContext[], consultationId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            consultation.context = contextDTO.map(parseContext)
        },
        setContext(contextDTO: DTOContext[], parent: string, parent_id: number) {

        },
        setCustomPage(customPageDTO: DTOCustomPage[]) {
            this.community.customPages = customPageDTO.map(parseCustomPage);
            // this.community.customPages = parseCustomPage(customPageDTO)
            this.customPageLoaded = true
        },
        setProjects(projectsDTO: DTOProject[]) {
            this.community.projects = projectsDTO.map(parseProject);
        },
        setProjectContext(contextDTO: DTOContext[], projectId: number) {
            const project = this.community.projects.find(project => project.id === projectId)!
            project.context = contextDTO.map(parseContext)
        },
        setSectionContext(contextDTO: DTOContext[], consultationId: number, sectionId: number) {
            const consultation = this.community.consultations.find(consultation => consultation.id === consultationId)!
            const section = consultation.section.find(s => s.id === sectionId)!
            section.context = contextDTO.map(parseContext)
        },
        setSectionForms(formDTO: DTOForm[], section: Section) {
            section.form = formDTO.map(parseForm);
        },
        getConsultationTimeLeft(consultation: Consultation): number {
            const startDate = consultation.beginDate ? consultation.beginDate : todayInTimestamp();
            const endDate = consultation.endDate;
            let diffDays: number;
            diffDays = diffInDays(startDate, endDate);
            if (diffDays <= 0) {
                return 0;
            }
            return diffDays;
        },
        addVoteToFormById(response: PostFormVoteResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            form.response[0] = response.vote;
            form.statistiques = response.statistiques
        },
        addCommentToFormById(response: PostFormCommentResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            form.comments.push(response.comment)
        },
        updateCommentAction(response: PostFormCommentActionResponse) {
            const consultation = this.community.consultations.find(c => c.id === response.consultationId);
            const section = consultation.section.find(s => s.id === response.sectionId);
            let form = section.form.find(f => f.id === response.formId);
            let comment = form.comments.find(c => c.id === response.commentId);
            switch (response.action) {
                case 'like':
                    comment.likes.push({ action: 1, userId: response.userId })
                    if(form.type === 5){
                        const this_marker = form.mapMarkers.find(marker => marker.comment_id === response.commentId);
                        this_marker.likes.push({ action: 1, userId: response.userId })
                    }
                    break;
                case 'flag':
                    comment.flags.push({ action: 2, userId: response.userId })
                    break;
                case 'unlike':
                    const likeActionIndex = comment.likes.findIndex(a => a.userId === response.userId);
                    comment.likes.splice(likeActionIndex, 1)
                    break;

                case 'unflag':
                    const flagActionIndex = comment.flags.findIndex(a => a.userId === response.userId);
                    comment.flags.splice(flagActionIndex, 1);
                    break;
            }

        },
        async getCustomPageBySlug(slug: string): Promise<CustomPage> {
            return this.community.customPages.find((customPage: any) => customPage.slug === slug);
        },
        isUserAllowedAccessConsultation(consultation: Consultation, user: User) {
            const userStore = useUserStore()
            if (consultation.type === constant.Consultation.Type.Public) {
                return { allowed: true };
            }
            if (!user.email) {
                return { allowed: false, reason: 'login' };
            }
            if (userStore.isAdmin) {
                return { allowed: true };
            }
            const emailDomain = user.email.split('@');
            if (consultation.privateEmailAllowed.email.includes(user.email) || consultation.privateEmailAllowed.domain.includes(emailDomain[1])) {
                return { allowed: true }
            } else {
                return { allowed: false, reason: 'NotAllowedByAdmin' }
            }

        }
    },
    getters: {
        openConsultations(state): HomepageItem[] {
            const currentDate = new Date();
            const consultations = state.community.consultations?.filter(
                (consultation) =>
                    consultation.status === constant.Consultation.Status.Active &&
                    (consultation.endDate === null || new Date(consultation.endDate) >= currentDate)
                    && (consultation.type === constant.Consultation.Type.Public || consultation.options.show_private_everyone || this.isUserAllowedAccessConsultation(consultation, useUserStore().user).allowed)
            ) || [];
            const projects = state.community.projects?.filter((project: Project) => project.status === constant.Consultation.Status.Active) || [];
            return [...consultations, ...projects];
        },
        preLaunchedConsultations(state) {
            return state.community.consultations?.filter(
                (consultation) =>
                    consultation.status === constant.Consultation.Status.PreLaunch
            );
        },
        archivedConsultations(state) {
            return state.community.consultations?.filter(
                (consultation) =>
                    consultation.status === constant.Consultation.Status.Deleted
            );
        },
        closedConsultations(state) {
            const currentDate = new Date();
            return state.community.consultations?.filter(
                (consultation) =>
                    (consultation.status === constant.Consultation.Status.Active &&
                        (consultation.endDate !== null && new Date(consultation.endDate) < currentDate)) || (consultation.status === constant.Consultation.Status.Completed)
                    && (consultation.type === constant.Consultation.Type.Public || consultation.options.show_private_everyone || this.isUserAllowedAccessConsultation(consultation, useUserStore().user).allowed)
            );
        },
        getSections(state) {
            return (consultationId: number) => state.community.consultations.find((consultation) => consultation.id === consultationId)?.section
        },
    }
});

function parseCommunity(dtoCommunity: DTOCommunity): Community {
    return {
        id: dtoCommunity.id,
        slug: dtoCommunity.slug,
        createdAt: dtoCommunity.created_at,
        status: dtoCommunity.status,
        contactEmail: dtoCommunity.contact_email,
        website: dtoCommunity.website,
        phone: dtoCommunity.phone,
        googleAnalyticsCode: dtoCommunity.google_analytics_code,
        clarityCode: dtoCommunity.clarity_code,
        title: dtoCommunity.title,
        description: dtoCommunity.description,
        features: dtoCommunity.features,
        bannerFile: dtoCommunity.banner_file,
        logoFile: dtoCommunity.logo_file,
        faviconFile: dtoCommunity.favicon_file,
        consultations: [],
        customPages: [],
        languageEnabled: dtoCommunity.language_enabled,
        contactInfo: dtoCommunity.contact_info,
        options: dtoCommunity.options,
        organizationName: dtoCommunity.organization_name ?? ''
    }
}
