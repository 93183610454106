import { Config } from '@/config';
import apiService from "@/services/api.service";
import { DTOProject, Project, useProjectStore } from '@/store/projectStore';
import { useCommunityStore } from '@/store/communityStore';

class ProjectsService {
    async fetchProjects(): Promise<boolean> {
        // if (cancelTokenConsultations) {
        //     cancelTokenConsultations.cancel('Multiple requests.');
        // }

        // cancelTokenConsultations = CancelToken.source();

        const response = await apiService.get<DTOProject[]>(`${Config.api.backend.urlV2}/projects`);

        if (!response.success || !response.data) {
            // TODO how to handle API failures?
            console.error('Failed to get projects');
            return false;
        }

        useCommunityStore().setProjects(response.data);
        return true;
    }

    async getProjectsByCommunity() {
        try {
            const communityStore = useCommunityStore()
            const requestData = {
                id: communityStore.community.id
            };

            const response = await apiService.get(
                `${Config.api.backend.urlV2}/projects/`, requestData
            );
            return response.data
        } catch (error) {
            return error.response.data;
        }
    }

    async getProjectById(projectId: number) {
        try {
            const communityStore = useCommunityStore()
            const requestData = {
                id: communityStore.community.id
            };

            const response = await apiService.get(
                `${Config.api.backend.urlV2}/projects/${projectId}`, requestData
            );
            return response.data
        } catch (error) {
            return error.response.data;
        }
    }

}

export const projectsService = new ProjectsService();