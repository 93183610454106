<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { getTranslation } from "@/utils/data-display-utils";

import ProjectAttachments from '@/components/project/ProjectAttachments.vue';
import ProjectDescription from '@/components/project/ProjectDescription.vue';
import { Project } from "@/store/project.interface";
import { useCommunityStore } from "@/store/communityStore";
import { contextService } from "@/services/context.service";


// CONST
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const communityStore = useCommunityStore();


// LIFECYCLE
onMounted(async () => {
    await contextService.getProjectContext(project.value.id)
})


// COMPUTED
const project = computed<Project>(() => {
    const slug = route.params.slug as string
    return communityStore.community.projects.find(project => project.slug == slug);
})
</script>

<template>
    <div class="project-container">
        <div class="project">
            <div class="project__title">
                <h1>{{ getTranslation(project, 'title') }}</h1>
            </div>
            <div class="project__description">
                <ProjectDescription :text="getTranslation(project, 'description')" :banner="project.bannerFile" />
            </div>
            <div class="project__supporting-infos">
                <project-attachments :infos="project.context"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.project-container {
    display: flex;
    justify-content: center;
    padding: $space-md 0;

    .project {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: $space-lg;

        &__title {
            margin: $space-md 0 0 0;
        }

        &__supporting-infos {
            width: 100%;
        }
    }
}

@media screen and (max-width: $breakpoint-lg) {
    .project-container {
        padding: $space-sm 0;

        .project {
            width: 90%;
        }
    }
}
</style>