<script setup lang="ts">
// PROPS
const props = defineProps<{
	text: string;
	destination: string;
	isSmall?: boolean;
	isDisabled?: boolean;
}>();
</script>

<template>
	<a	class="base-link"
		:class="{ 'is-disabled': isDisabled, 'is-small': isSmall }"
		:href="props.destination"
		target="_blank"
	>
		{{ props.text}}
	</a>
</template>

<style lang="scss"></style>
