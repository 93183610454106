function createPointGeoJson(categoryData: any[], markerData: any[]) {

    const categoryMap = categoryData.map(category => ({

        category: category,
        geoJson: {
            type: "FeatureCollection",
            features: markerData
                .filter(marker => marker.marker_category === category.id)
                .map(marker => {
                    const feature = {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: marker.marker_location
                        },
                        properties: {
                            title: marker.marker_text,
                            "markerColor": category.color,
                            "markerSize": "medium",
                            "markerCategoryId": category.id,
                            "markerUserId": marker.user_id,
                            "markerUser": marker.user,
                            "markerLike": marker.likes,
                            "markerCommentId": marker.comment_id,
                            "markerFormId": marker.form_id,
                        }
                    };
                    return feature;
                })
        }
    }));
    return categoryMap
}
export { createPointGeoJson };